export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ACCESS_TOKEN_ERROR = "ACCESS_TOKEN_ERROR";
export const ACCESS_TOKEN_SUCCESS = "ACCESS_TOKEN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

//PROFILE REDUCER
export const AUTH_USER_LOAD = "AUTH_USER_LOAD";
export const AUTH_USER_LOAD_ERROR = "AUTH_USER_LOAD_ERROR";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const PROFILE_UPDATE_ERROR = "PROFILE_UPDATE_ERROR";

// GAME REDUCER
export const GET_GAME_LIST = "GET_GAME_LIST";
export const SELECT_GAME = "SELECT_GAME";
export const CREATE_GAME = "CREATE_GAME";
export const CREATE_GAME_ERROR = "CREATE_GAME_ERROR";

// PLAYER REDUCER
export const GET_PLAYER_DETAILS = "GET_PLAYER_DETAILS";
export const GET_PLAYER_DETAILS_ERROR = "GET_PLAYER_DETAILS_ERROR";
export const GET_PLAYER_LIST = "GET_PLAYER_LIST";
export const CREATE_PLAYER = "CREATE_PLAYER";
export const CREATE_PLAYER_ERROR = "CREATE_PLAYER_ERROR";
export const UPDATE_PLAYER = "UPDATE_PLAYER";
export const UPDATE_PLAYER_ERROR = "UPDATE_PLAYER_ERROR";
export const DELETE_PLAYER = "DELETE_PLAYER";
export const DELETE_PLAYER_ERROR = "DELETE_PLAYER_ERROR";

// CATEGORY REDUCER
export const GET_CATEGORY_DETAILS = "GET_CATEGORY_DETAILS";
export const GET_CATEGORY_DETAILS_ERROR = "GET_CATEGORY_DETAILS_ERROR";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_ERROR = "CREATE_CATEGORY_ERROR";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";

// AUTHOR REDUCER
export const GET_AUTHOR_DETAILS = "GET_AUTHOR_DETAILS";
export const GET_AUTHOR_DETAILS_ERROR = "GET_AUTHOR_DETAILS_ERROR";
export const GET_AUTHOR_LIST = "GET_AUTHOR_LIST";
export const CREATE_AUTHOR = "CREATE_AUTHOR";
export const CREATE_AUTHOR_ERROR = "CREATE_AUTHOR_ERROR";
export const UPDATE_AUTHOR = "UPDATE_AUTHOR";
export const UPDATE_AUTHOR_ERROR = "UPDATE_AUTHOR_ERROR";
export const DELETE_AUTHOR = "DELETE_AUTHOR";
export const DELETE_AUTHOR_ERROR = "DELETE_AUTHOR_ERROR";

// BOOK REDUCER
export const GET_BOOK_DETAILS = "GET_BOOK_DETAILS";
export const GET_BOOK_DETAILS_ERROR = "GET_BOOK_DETAILS_ERROR";
export const GET_BOOK_LIST = "GET_BOOK_LIST";
export const CREATE_BOOK = "CREATE_BOOK";
export const CREATE_BOOK_ERROR = "CREATE_BOOK_ERROR";
export const UPDATE_BOOK = "UPDATE_BOOK";
export const UPDATE_BOOK_ERROR = "UPDATE_BOOK_ERROR";
export const DELETE_BOOK = "DELETE_BOOK";
export const DELETE_BOOK_ERROR = "DELETE_BOOK_ERROR";

// SERIES REDUCER
export const GET_SERIES_DETAILS = "GET_SERIES_DETAILS";
export const GET_SERIES_DETAILS_ERROR = "GET_SERIES_DETAILS_ERROR";
export const GET_SERIES_LIST = "GET_SERIES_LIST";
export const CREATE_SERIES = "CREATE_SERIES";
export const CREATE_SERIES_ERROR = "CREATE_SERIES_ERROR";
export const UPDATE_SERIES = "UPDATE_SERIES";
export const UPDATE_SERIES_ERROR = "UPDATE_SERIES_ERROR";
export const DELETE_SERIES = "DELETE_SERIES";
export const DELETE_SERIES_ERROR = "DELETE_SERIES_ERROR";

// COLLECTION REDUCER
export const GET_COLLECTION_DETAILS = "GET_COLLECTION_DETAILS";
export const GET_COLLECTION_DETAILS_ERROR = "GET_COLLECTION_DETAILS_ERROR";
export const GET_COLLECTION_LIST = "GET_COLLECTION_LIST";
export const ADD_INTO_COLLECTION = "ADD_INTO_COLLECTION";
export const ADD_INTO_COLLECTION_ERROR = "ADD_INTO_COLLECTION_ERROR";
export const REMOVE_FROM_COLLECTION = "REMOVE_FROM_COLLECTION";
export const REMOVE_FROM_COLLECTION_ERROR = "REMOVE_FROM_COLLECTION_ERROR";
export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export const UPDATE_COLLECTION_ERROR = "UPDATE_COLLECTION_ERROR";
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const DELETE_COLLECTION_ERROR = "DELETE_COLLECTION_ERROR";

// PUBLISHER REDUCER
export const GET_PUBLISHER_DETAILS = "GET_PUBLISHER_DETAILS";
export const GET_PUBLISHER_DETAILS_ERROR = "GET_PUBLISHER_DETAILS_ERROR";
export const GET_PUBLISHER_LIST = "GET_PUBLISHER_LIST";
export const CREATE_PUBLISHER = "CREATE_PUBLISHER";
export const CREATE_PUBLISHER_ERROR = "CREATE_PUBLISHER_ERROR";
export const UPDATE_PUBLISHER = "UPDATE_PUBLISHER";
export const UPDATE_PUBLISHER_ERROR = "UPDATE_PUBLISHER_ERROR";
export const DELETE_PUBLISHER = "DELETE_PUBLISHER";
export const DELETE_PUBLISHER_ERROR = "DELETE_PUBLISHER_ERROR";

// SUBCATEGORY REDUCER
export const GET_SUBCATEGORY_DETAILS = "GET_SUBCATEGORY_DETAILS";
export const GET_SUBCATEGORY_DETAILS_ERROR = "GET_SUBCATEGORY_DETAILS_ERROR";
export const GET_SUBCATEGORY_LIST = "GET_SUBCATEGORY_LIST";
export const CREATE_SUBCATEGORY = "CREATE_SUBCATEGORY";
export const CREATE_SUBCATEGORY_ERROR = "CREATE_SUBCATEGORY_ERROR";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const UPDATE_SUBCATEGORY_ERROR = "UPDATE_SUBCATEGORY_ERROR";
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const DELETE_SUBCATEGORY_ERROR = "DELETE_SUBCATEGORY_ERROR";

// TEAM REDUCER
export const GET_TEAM_DETAILS = "GET_TEAM_DETAILS";
export const GET_TEAM_DETAILS_ERROR = "GET_TEAM_DETAILS_ERROR";
export const GET_TEAM_LIST = "GET_TEAM_LIST";
export const CREATE_TEAM = "CREATE_TEAM";
export const CREATE_TEAM_ERROR = "CREATE_TEAM_ERROR";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_TEAM_ERROR = "UPDATE_TEAM_ERROR";

// LANDING
export const GET_THIS_WEEK_BOOK = "GET_THIS_WEEK_BOOK";
export const GET_BEST_SELLER = "GET_BEST_SELLER";
export const GET_POPULAR = "GET_POPULAR";
export const GET_PICKED = "GET_PICKED";
export const GET_FAV_BOOK = "GET_FAV_BOOK";
export const GET_NEW_BOOK = "GET_NEW_BOOK";
